import React, { useMemo } from 'react';
import { ColumnConfig, DataConnection, useQueryVariables } from 'hooks';
import { ProjectExpense, useProjectExpensesQuery } from 'schema';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { CompleteIcon, DataGridProps, WaitIcon } from 'components';
import { useStyles } from './useStyles';
import moment from 'moment';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';

const getDataKey = (project: ProjectExpense) => project.id;

const getReferenceDate = (project: ProjectExpense) => {
  return moment(project?.referenceDate as string).format('D MMMM YYYY');
};

export const useAccounts = () => {
  const classes = useStyles();

  const config = useMemo(() => {
    /**
     * Конфиг колонок для таблицы
     */
    const columns: ColumnConfig<ProjectExpense>[] = [
      {
        id: 'title',
        header: 'Название',
        render: (project: ProjectExpense) => {
          return (
            <Link className={classes.link} to={`/project/${project.id}/projectExpense`}>
              {project.title}
            </Link>
          );
        },
      },
      {
        id: 'endDate',
        header: 'Дата закрытия',
        render: (project: ProjectExpense) => {
          return getReferenceDate(project);
        },
      },
      {
        id: 'status',
        header: 'Статус',
        align: 'center',
        render: (project: ProjectExpense) =>
          project.reportSentAt ? (
            <CompleteIcon className={classes.completeIcon} />
          ) : (
            <WaitIcon className={classes.iconColor} />
          ),
      },
      {
        id: 'hasDocuments',
        header: 'Документы',
        align: 'center',
        render: (account: ProjectExpense) =>
          !!account?.documents?.length && (
            <Link to={`/account/${account.id}`}>
              <DocumentIcon className={classes.iconColor} />
            </Link>
          ),
      },
      {
        id: 'publishedAt',
        header: 'Публичный',
        align: 'center',
        render: (project: ProjectExpense) =>
          project.publishedAt ? <CompleteIcon className={classes.completeIcon} /> : null,
      },
      {
        id: 'actions',
        header: '',
        render: (project: ProjectExpense) => {
          return (
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              size="small"
              component={Link}
              to={`/project/${project.id}/projectExpense`}
            >
              Детали
            </Button>
          );
        },
      },
    ];

    return {
      columns,
    };
  }, [classes]);

  /**
   * Подготовка переменных запроса
   */
  const { variables, extractPagedData, ...restVariables } = useQueryVariables<ProjectExpense>(
    config
  );

  /**
   * Запрос
   */
  const { data, loading } = useProjectExpensesQuery({
    variables: {
      ...variables,
    },
  });

  /**
   * Парсинг данных
   */
  const entities = data?.project?.expenses as DataConnection<ProjectExpense>;
  const { data: donations, count, startCursor, endCursor, page, rowsPerPage } = extractPagedData(
    entities
  );

  /**
   * Свойства для компонента Grid
   */
  const gridProps: DataGridProps<ProjectExpense> = {
    ...restVariables,
    data: donations,
    getDataKey,
    count,
    startCursor: startCursor,
    endCursor: endCursor,
    page,
    rowsPerPage,
  };

  return { donations, gridProps, loading };
};
