import React, { useEffect, useState } from 'react';
import {
  ApproveFoundationEventInput,
  CancelFoundationEventInput,
  CloseFoundationEventInput,
  CloseFoundationEventRegistrationInput,
  FoundationEvent,
  FoundationEventByIdDocument,
  FoundationEventByIdQueryVariables,
  FoundationEventPermissions,
  OpenFoundationEventRegistrationInput,
  RequestFoundationEventApprovalInput,
  TakeFoundationEventOwnershipInput,
  useApproveFoundationEventMutation,
  useCancelFoundationEventMutation,
  useCloseFoundationEventMutation,
  useCloseFoundationEventRegistrationMutation,
  useOpenFoundationEventRegistrationMutation,
  useRequestFoundationEventApprovalMutation,
  useTakeFoundationEventOwnershipMutation,
} from 'schema';
import {
  Avatar,
  Button,
  CardActions,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { MutableButton } from 'ui/MutableButton';
import { useMeContext } from 'components';
import clsx from 'clsx';
import { AuditTable } from 'components/AuditTable/AuditTable';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import 'moment-timezone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerLabel: {
      fontWeight: 'bolder',
      marginBottom: '10px',
    },
    avatar: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      marginBottom: theme.spacing(2),
    },
    headerAge: {
      marginTop: theme.spacing(1),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    button: {
      marginTop: '10px',
    },
    description: {
      marginTop: '10px',
    },
    cancelButton: {
      background: theme.palette.warning.dark,
      color: '#fff',
      '&:hover': {
        background: theme.palette.warning.light,
      },
    },
    cancelledText: {
      textDecoration: 'line-through',
      color: theme.palette.error.main,
    },
    auditTable: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      width: '100%',
    },
    header: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    alertText: {
      fontSize: '16px',
      lineHeight: 1.3,
      color: theme.palette.error.main,
    },
  })
);

interface InformationBlockProps {
  permissions?: FoundationEventPermissions;
  event?: FoundationEvent;
}

type EventMutationsType =
  | ApproveFoundationEventInput
  | RequestFoundationEventApprovalInput
  | CancelFoundationEventInput
  | CloseFoundationEventInput
  | TakeFoundationEventOwnershipInput
  | OpenFoundationEventRegistrationInput
  | CloseFoundationEventRegistrationInput;

export const InformationBlock = (props: InformationBlockProps) => {
  const { permissions, event } = props;
  const { pathname } = useLocation();
  const styles = useStyles();

  const { user } = useMeContext();

  const eventDate = (start: string) => {
    return moment.tz(start, 'Europe/Moscow').format('DD.MM.YYYY HH:mm');
  };
  const mutationsInput: EventMutationsType = {
    foundationEventId: event?.id || '',
  };

  const isOwner = user && event && user.id === event.owner.user?.id;

  const eventByIdVariables: FoundationEventByIdQueryVariables = {
    id: event?.id || '',
  };

  const variables = {
    input: mutationsInput,
  };

  const createVariables = {
    variables,
    refetchQueries: [
      {
        query: FoundationEventByIdDocument,
        variables: eventByIdVariables,
      },
    ],
  };

  const [
    requestFoundationEventApprovalMutation,
    { loading: approvalLoading, error: requestEventApprovalError },
  ] = useRequestFoundationEventApprovalMutation();
  const [
    approveFoundationEventMutation,
    { loading: approveEventLoading, error: approveEventError },
  ] = useApproveFoundationEventMutation();
  const [
    cancelFoundationEventMutation,
    { loading: cancelEventLoading, error: cancelEventError },
  ] = useCancelFoundationEventMutation();
  const [
    closeFoundationEventMutation,
    { loading: closeEventLoading, error: closeEventError, data: dataCloseEvent },
  ] = useCloseFoundationEventMutation();
  const [
    takeFoundationEventOwnershipMutation,
    { loading: takeEventOwnershipLoading, error: takeEventOwnershipError },
  ] = useTakeFoundationEventOwnershipMutation();
  const [
    closeFoundationEventRegistrationMutation,
    { loading: closeRegistrationLoading, error: closeRegistrationError },
  ] = useCloseFoundationEventRegistrationMutation();
  const [
    openFoundationEventRegistrationMutation,
    { loading: openRegistrationLoading, error: openRegistrationError },
  ] = useOpenFoundationEventRegistrationMutation();

  const notClosedOrCancelled = !(event?.closed || event?.cancelled);
  const dateClassName = event?.cancelled
    ? clsx(styles.headerAge, styles.cancelledText)
    : styles.headerAge;

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleCancelEventButtonClick = () => {
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleCancelEvent = async () => {
    setConfirmationDialogOpen(false);

    await cancelFoundationEventMutation(createVariables);
  };

  const error =
    requestEventApprovalError ||
    approveEventError ||
    cancelEventError ||
    takeEventOwnershipError ||
    closeRegistrationError ||
    openRegistrationError ||
    closeEventError ||
    false;

  useEffect(() => {
    error && toast.error('Что-то пошло не так...');

    dataCloseEvent?.closeFoundationEvent.errors &&
      toast.error(`${dataCloseEvent?.closeFoundationEvent.errors.map((error) => error.message)}`);
  }, [error, dataCloseEvent]);

  console.log(event?.details.start);

  return (
    <>
      <Typography component="div" variant="h4" align="center" className={styles.headerLabel}>
        Информация о событии
      </Typography>
      <Avatar
        alt={event?.details.title}
        src={event?.details.images[0].url}
        className={styles.avatar}
      />
      <Typography component="div" variant="h5" align="center" className={styles.headerLabel}>
        {event?.details.city}
      </Typography>
      <Typography component="div" variant="h5" align="center" className={styles.headerLabel}>
        {event?.details.address.displayText}
      </Typography>
      <Typography component="span" variant="subtitle2" align="center" className={dateClassName}>
        {`${eventDate(event?.details.start)} (время для ${event?.details?.city})`}
      </Typography>
      <CardActions className={styles.buttons}>
        {notClosedOrCancelled && permissions?.edit ? (
          <Button
            className={styles.button}
            color="primary"
            variant="contained"
            component={Link}
            to={`${pathname}/edit`}
          >
            Редактировать событие
          </Button>
        ) : null}
        {permissions?.edit ? (
          <Button
            className={styles.button}
            color="primary"
            variant="contained"
            component={Link}
            to={`${pathname}/report`}
          >
            Редактировать отчет
          </Button>
        ) : null}
      </CardActions>
      {notClosedOrCancelled ? (
        <CardActions className={styles.buttons}>
          {permissions?.requestApproval && !event?.approvalRequested && !event?.approved ? (
            <MutableButton
              className={styles.button}
              color="primary"
              variant="contained"
              loading={approvalLoading}
              onClick={() => requestFoundationEventApprovalMutation(createVariables)}
            >
              Запросить одобрение
            </MutableButton>
          ) : null}
          {user?.permissions.foundations.approveEvents &&
          !event?.approved &&
          event?.approvalRequested ? (
            <MutableButton
              className={styles.button}
              color="primary"
              variant="contained"
              loading={approveEventLoading}
              onClick={() => approveFoundationEventMutation(createVariables)}
            >
              Одобрить
            </MutableButton>
          ) : null}
          {permissions?.openOrCloseRegistration && event?.approved && !event?.registrationClosed ? (
            <MutableButton
              className={styles.button}
              color="primary"
              variant="contained"
              loading={closeRegistrationLoading}
              onClick={() => closeFoundationEventRegistrationMutation(createVariables)}
            >
              Закрыть регистрацию
            </MutableButton>
          ) : null}
          {permissions?.openOrCloseRegistration && event?.approved && event?.registrationClosed ? (
            <MutableButton
              className={styles.button}
              color="primary"
              variant="contained"
              loading={openRegistrationLoading}
              onClick={() => openFoundationEventRegistrationMutation(createVariables)}
            >
              Открыть регистрацию
            </MutableButton>
          ) : null}
          {permissions?.close && event?.approved ? (
            <MutableButton
              className={styles.button}
              color="primary"
              variant="contained"
              loading={closeEventLoading}
              onClick={() => closeFoundationEventMutation(createVariables)}
            >
              Закрыть событие
            </MutableButton>
          ) : null}
        </CardActions>
      ) : null}
      {notClosedOrCancelled && (permissions?.close || permissions?.takeOwnership) ? (
        <CardActions className={styles.buttons}>
          {!isOwner && permissions?.takeOwnership ? (
            <MutableButton
              className={styles.button}
              color="secondary"
              variant="contained"
              loading={takeEventOwnershipLoading}
              onClick={() => takeFoundationEventOwnershipMutation(createVariables)}
            >
              Стать ответственным
            </MutableButton>
          ) : null}
          {permissions?.close ? (
            <MutableButton
              className={clsx(styles.button, styles.cancelButton)}
              color="secondary"
              variant="contained"
              loading={cancelEventLoading}
              onClick={() => handleCancelEventButtonClick()}
            >
              Отменить событие
            </MutableButton>
          ) : null}
        </CardActions>
      ) : null}
      <Typography className={styles.description} component="div" align="left">
        {event?.details.description}
      </Typography>
      <div className={styles.auditTable}>
        <AuditTable
          items={[
            { caption: 'Ответственный', marker: event?.owner },
            { caption: 'Создал', marker: event?.created },
            { caption: 'Утвердил', marker: event?.approved },
            { caption: 'Закрыл', marker: event?.closed },
            { caption: 'Отменил', marker: event?.cancelled },
          ]}
        />
      </div>
      {event?.checkinUrl !== null ? (
        <>
          <Typography component="div" variant="h4" align="center" className={styles.headerLabel}>
            QR-код для регистрации
          </Typography>
          <QRCode value={event?.checkinUrl!} />
        </>
      ) : null}
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title" disableTypography className={styles.header}>
          {'Подтвердите отмену события'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description" className={styles.alertText}>
            Отмена события повлечёт за собой рассылку уведомлений всем выбранным помощникам. Вы
            больше не сможете изменять информацию о событии!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEvent}>Отменить событие</Button>
          <Button onClick={handleConfirmationDialogClose} autoFocus>
            Не отменять событие
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
