import React from 'react';
import {
  AutoFocusedForm,
  PhotoField,
  required,
  SelectField,
  StorySelect,
  TextField,
} from 'components';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Loading } from 'ui/Loading';
import { Field } from 'react-final-form';
import { useAwardEditModalForm } from './useAwardEditModalForm';
import { MutableButton } from '../../../../ui/MutableButton';
import { RedButton } from '../../../../components/Buttons';
import { AchievementByIdQuery } from '../../../../schema';
import { useStyles } from '../useStyles';

interface AwardEditModalFormProps {
  onClose: () => void;
  achievement: AchievementByIdQuery | undefined;
}

export const AwardEditModalForm = ({ onClose, achievement }: AwardEditModalFormProps) => {
  const styles = useStyles();

  const { onSubmit, getInitialValues, getLoading } = useAwardEditModalForm({
    onClose,
    achievement,
  });

  const initialValues = getInitialValues();

  if (getLoading()) {
    return <Loading />;
  }

  return (
    <AutoFocusedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={`Редактировать награду`} />
              <Divider />
              <CardContent className={styles.content}>
                <Box className={styles.images}>
                  <Grid container>
                    <Grid item lg={8} md={9} xl={9} xs={9}>
                      <Field
                        name="imageId"
                        component={PhotoField}
                        url={achievement?.achievementById?.image.url}
                      />
                      <Typography variant="body2">Изображение достижения</Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item lg={8} md={9} xl={9} xs={9}>
                      <Field
                        name="topImageId"
                        component={PhotoField}
                        url={achievement?.achievementById?.topImage?.url}
                      />
                      <Typography variant="body2">Изображение списка TOP-N историй</Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="title"
                      component={TextField}
                      rows={3}
                      multiline
                      label="Название награды"
                      validate={required}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="description"
                      component={TextField}
                      multiline
                      rows={3}
                      label="Описание награды"
                      validate={required}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <StorySelect
                      storyLabel="achievements"
                      name="storyId"
                      label="История с описанием награды"
                      validate={required}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="topPosition"
                      type="number"
                      component={TextField}
                      label="Номер в топе"
                    />
                  </Grid>
                  {achievement?.achievementById?.__typename === 'AchievementWithMaterialPrizes' && (
                    <Grid item lg={6} md={6} xl={6} xs={6}>
                      <Field
                        name="totalNumberOfPrizes"
                        type="number"
                        component={TextField}
                        label="Общее количество призов"
                        validate={required}
                      />
                    </Grid>
                  )}
                  {achievement?.achievementById?.__typename === 'AchievementWithMaterialPrizes' && (
                    <Grid item lg={6} md={6} xl={6} xs={6}>
                      <Field
                        name="numberOfGrantedPrizes"
                        type="number"
                        component={TextField}
                        label="Количество выданных призов"
                        validate={required}
                      />
                    </Grid>
                  )}

                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="isHighlighted"
                      component={SelectField}
                      label="Достижение выделяется?"
                      validate={required}
                    >
                      <MenuItem value={(true as unknown) as string}>Да</MenuItem>
                      <MenuItem value={(false as unknown) as string}>Нет</MenuItem>
                    </Field>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions className={styles.actions}>
                <MutableButton
                  loading={false}
                  className={styles.accessButton}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить
                </MutableButton>
                <RedButton onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
