import React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { InvisibleCard } from '../../../ui/InvisibleCard';
import { AccountActions, AccountDonations } from '../../account/components';
import {
  ProjectExpense,
  ProjectExpenseAccount,
  useProjectExpensesQuery,
  usePublishExpenseMutation,
  useUnpublishExpenseMutation,
} from '../../../schema';
import { LayoutPageGrid } from '../../../ui/LayoutGrid';
import { useParams } from 'react-router-dom';
import { Loading } from '../../../ui/Loading';
import { toast } from 'react-toastify';
import { ProjectExpenseInformation } from './components/ProjectExpenseInformation';
import { ProjectExpenseDocuments } from './components/ProjectExpenseDocuments';
import { MutableButton } from '../../../ui/MutableButton';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
    },
    button: {
      margin: '0 !important',
    },
  })
);

const ProjectExpensePage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data, loading, error } = useProjectExpensesQuery();
  const styles = useStyles();
  const projectExpense = data?.project.expenses?.nodes?.find(
    ({ id }) => id === projectId
  ) as ProjectExpense;

  const [publishExpense, { loading: publishLoading }] = usePublishExpenseMutation({
    onCompleted: () => {
      toast.success('Показ отчета разрешен');
    },
    onError: () => {
      toast.error('Не удалось разрешить показ отчета');
    },
  });

  const [unpublishExpense, { loading: unpublishLoading }] = useUnpublishExpenseMutation({
    onCompleted: () => {
      toast.success('Показ отчета запрещен');
    },
    onError: () => {
      toast.error('Не удалось запретить показ отчета');
    },
  });

  const handlePublishToggle = () => {
    const input = { id: projectId };

    if (projectExpense?.publishedAt) {
      unpublishExpense({ variables: { input } });
    } else {
      publishExpense({ variables: { input } });
    }
  };

  if (error) {
    toast.error('Не удалось загрузить проект');
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <LayoutPageGrid title={`Счёт расходов проекта: ${projectExpense?.title}`}>
      <Card>
        <CardHeader title={`Информация о проекте`} />
        <CardContent>
          <InvisibleCard>
            <CardContent>
              <ProjectExpenseInformation projectExpense={projectExpense} />
            </CardContent>
            <CardActions className={styles.wrapper}>
              <AccountActions
                account={projectExpense?.account as ProjectExpenseAccount}
                project={projectExpense}
              />
              <MutableButton
                className={styles.button}
                color="primary"
                variant="contained"
                loading={publishLoading || unpublishLoading}
                onClick={handlePublishToggle}
              >
                {projectExpense?.publishedAt ? 'Запретить показ' : 'Разрешить показ'}
              </MutableButton>
            </CardActions>
          </InvisibleCard>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Пожертвования" />
        <CardContent>
          <AccountDonations id={projectExpense?.account?.id} />
        </CardContent>
        <CardContent>
          <ProjectExpenseDocuments projectExpense={projectExpense} />
        </CardContent>
      </Card>
    </LayoutPageGrid>
  );
};

export default ProjectExpensePage;
