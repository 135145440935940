import React, { useMemo } from 'react';
import { ProjectExpense } from 'schema';
import { PropertiesTable, PropertiesTableRow } from 'ui/PropertiesTable';
import moment from 'moment';

type Props = {
  projectExpense: ProjectExpense;
};
export const ProjectExpenseInformation = ({ projectExpense }: Props) => {
  //Если счёт не закрыт - сбор средств, если закрыт - средства собраны, если отчёт отправлен - отчёт отправлен

  const status = useMemo<string>(() => {
    if (projectExpense?.reportSentAt) {
      return 'Отчёт отправлен';
    } else if (projectExpense?.account.closedAt) {
      return 'Средства собраны';
    } else {
      return 'Сбор средств';
    }
  }, [projectExpense]);

  const reportPublishStatus = useMemo<string>(() => {
    return projectExpense?.publishedAt ? 'Разрешен' : 'Запрещен';
  }, [projectExpense]);

  return (
    <PropertiesTable>
      <PropertiesTableRow caption="Баланс" value={projectExpense.account.amount} isAmount />
      <PropertiesTableRow
        caption="Необходимая сумма"
        value={projectExpense.requiredAmount}
        isAmount
      />
      <PropertiesTableRow
        caption="Дата завершения"
        value={moment(projectExpense.referenceDate).format('DD.MM.YYYY')}
      />
      {projectExpense.reportSentAt && (
        <PropertiesTableRow
          caption="Дата отправки отчёта"
          value={moment(projectExpense.reportSentAt).format('DD.MM.YYYY')}
        />
      )}
      <PropertiesTableRow caption="Статус" value={status} />
      <PropertiesTableRow caption="Показ отчета" value={reportPublishStatus} />
    </PropertiesTable>
  );
};
